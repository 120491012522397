.abbreviated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.photo {
    width: 100%;
}
